export default class ExecuteArgs {
    constructor(component, endPoint, method, postedData) {
        this.component = component;
        this.endPoint = endPoint;
        this.postedData = postedData;
        this.method = method;
       
    }
   
    setMethod = (methodname) => {
        this.method = methodname;
    }
    setPostedData = (posteddata) => {
        this.postedData = posteddata;
    }
}