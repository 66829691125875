import React from 'react';
import { Container } from 'reactstrap';


export default props => (
    <React.Fragment>

    {/* <Container> */}
      {props.children}
    {/* </Container> */}
    </React.Fragment>
);
